import { FC, useEffect, useRef, useState, ReactNode } from 'react';
import { useStore } from '@/store/store';
import styled, { keyframes } from 'styled-components';

interface TitleBlockProps {
  children?: ReactNode;
  hasMargin?: boolean;
}

interface TitleProps {
  hasMargin?: boolean;
  isVisible?: boolean;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const Title = styled.div<TitleProps>`
  font-size: 0.8rem;
  text-transform: uppercase;
  padding: 24px 4px 4px 0;
  margin-left: ${(props) => (props.hasMargin ? '0' : 'var(--plasma-grid-margin)')};
  animation: ${(props) => (props.isVisible ? fadeIn : fadeOut)} 0.5s ease forwards;
  color: #ffffff8f;
`;

const TitleBlock: FC<TitleBlockProps> = ({ children, hasMargin }) => {
  const [isVisible, setIsVisible] = useState(true);
  const titleRef = useRef<HTMLDivElement | null>(null);

  // const getDeviceType = () => {
  //   return window.navigator.userAgent.toLowerCase().includes('sberbox') ? 'sberBox' : 'mobile';
  // };
  //
  // if (getDeviceType() === 'sberBox') {
  //   if (typeof children === 'string') {
  //     addTitleToStore(children);
  //   }
  // }

  useEffect(() => {
    const handleScroll = () => {
      const rect = titleRef.current?.getBoundingClientRect();
      if (rect && rect.top <= 35 && isVisible) {
        setIsVisible(false);
        if (typeof children === 'string') {
          // addTitleToStore(children);
        }
      } else if (rect && rect.top > 35 && !isVisible) {
        setIsVisible(true);
        // removeTitleFromStore();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible, children]);

  return (
    <Title ref={titleRef} hasMargin={!!hasMargin} isVisible={isVisible}>
      {children}
    </Title>
  );
};

export default TitleBlock;
