import { FC, ReactNode, CSSProperties } from 'react';
import styled from 'styled-components';
import { Card, CardBody, CardContent, CardMedia } from '@salutejs/plasma-ui';

const Icon = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  font-size: 24px;
  color: white;
`;

const Title = styled.div`
  position: absolute;
  bottom: 8px;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  left: 0.6rem;
  text-wrap: initial;
`;

const WrapperCard = styled(Card)<{ isSelected: boolean }>`
  cursor: pointer;
  width: 13vw;
  height: 13vw;
  min-width: 126px;
  min-height: 126px;
  margin: 8px 8px 8px 0;
  background-color: ${(props) =>
    props.isSelected ? 'rgb(52 52 52)' : 'rgba(255, 255, 255, 0.06)'};
  border: ${(props) => (props.isSelected ? '2px solid white' : '')};
  overflow: hidden;
  border-radius: 12px;

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 33%;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(10 10 10 / 81%));
  }

  @media (max-width: 768px) {
    width: 20vw;
    height: 20vw;
  }

  @media (max-width: 480px) {
    width: 30vw;
    height: 30vw;
  }
`;

const StyledCardMedia = styled(CardMedia)`
  border-radius: 12px;
  width: 100%;
  height: 100%;
`;

interface CardStyleWrapperProps {
  title: string;
  children?: ReactNode;
  image: string;
  onClick: () => void;
  isSelected: boolean;
  style?: CSSProperties;
}

const CardStyleWrapper: FC<CardStyleWrapperProps> = ({
  title,
  children,
  image,
  onClick,
  isSelected,
  style
}) => {
  return (
    <WrapperCard isSelected={isSelected} onClick={onClick} style={style} tabIndex={-1}>
      <CardBody>
        {image !== '/images/style/none.jpg' && (
          <StyledCardMedia src={image} placeholder={title} ratio="1/1" />
        )}
        <CardContent cover>
          <Icon>{children}</Icon>
          <Title>{title}</Title>
        </CardContent>
      </CardBody>
    </WrapperCard>
  );
};

export default CardStyleWrapper;
