import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonProps,
  Col,
  Row,
  TextField,
  useToast,
  withAutoFocus
} from '@salutejs/plasma-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { styleImageConfigs } from '@/components/configs/styleConfigs';
import ImageWitchPoetry from '@/components/ImageWitchPoetry';
import {
  GetPostCardsQuery,
  PostCardEntity,
  useGetPostCardsQuery,
  useSetPublicStatusMutation
} from '@/graphql/graphQlApiHooks';
import { useAssistantContext } from '@/hooks/useAssistantContext';
import { useGenerateStore } from '@/store/generate';
import FullScreenImage from '@/components/FullScreenImage';
import { UUID_REGEX } from '@/components/HeaderMenuDesktop';
import { usePostCardStore } from '@/store/postCard';
import BtnShare from '@/components/BtnShare';
import useDeviceType from '@/hooks/useDeviceType';
import { BtnCopyMobile } from '@/components/Icons';

interface ImageInfoPageProps {
  currentImage?: PostCardEntity['image'];
}

const AutoFocusButton = withAutoFocus<ButtonProps>(Button);

const ImageInfoPage: FC<ImageInfoPageProps> = () => {
  const [showFullScreen, setShowFullScreen] = useState(false);

  const { userId } = useAssistantContext();
  const location = useLocation();
  const navigate = useNavigate();

  const { isDesktop, isMobile } = useDeviceType();

  const { setPrompt } = useGenerateStore((state) => ({
    setPrompt: state.setPrompt
  }));

  const { setPostCard, clearPostCard } = usePostCardStore((state) => ({
    setPostCard: state.setPostCard,
    clearPostCard: state.clearPostCard
  }));

  const { showToast } = useToast();

  const [postcard_MakePublic] = useSetPublicStatusMutation();
  const imagePublic = async (id: string) => {
    await postcard_MakePublic({
      variables: {
        input: { id, isPublic: true }
      }
    });
  };

  const match = location.pathname.match(UUID_REGEX) || [];
  const { data } = useGetPostCardsQuery({
    variables: {
      input: { id: match[0] ?? '' }
    },
    fetchPolicy: 'network-only'
  });

  const handleButtonClickGenerate = useCallback(
    (prompt: string) => {
      setPrompt(prompt);
      navigate('/generate');
    },
    [navigate]
  );

  useEffect(() => {
    if (data?.getPostCards) setPostCard(data.getPostCards[0]);
  }, [data]);

  useEffect(() => {
    return () => {
      clearPostCard();
    };
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('modal') === 'open') {
      setShowFullScreen(true);
    } else {
      setShowFullScreen(false);
    }
  }, [location.search]);

  useEffect(() => {
    const broadcast = new BroadcastChannel('generationActions');

    const handleMessage = (event: MessageEvent) => {
      const { action } = event.data;

      switch (action) {
        case 'publish':
          console.log('Publish command sent');
          if (data) {
            const { getPostCards } = data;
            const postCard = getPostCards[0];
            setPublicImage(postCard);
          }

          break;
      }
    };

    broadcast.addEventListener('message', handleMessage);

    return () => {
      broadcast.removeEventListener('message', handleMessage);
      broadcast.close();
    };
  }, [data]);

  const getStyleName = useCallback((styleName: string) => {
    return styleImageConfigs.find((style) => style.styleName === styleName)?.title;
  }, []);

  if (!data?.getPostCards) {
    return null;
  }

  const { getPostCards } = data;
  const postCard = getPostCards[0];

  const handleCloseFullScreen = () => {
    setShowFullScreen(false);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('modal');
    navigate(
      `${location.pathname.replace(/\/$/, '')}${
        searchParams.toString() ? `?${searchParams.toString()}` : ''
      }`
    );
  };

  const handleOpenFullScreen = () => {
    setShowFullScreen(true);
    navigate(`${location.pathname}/?modal=open`);
  };

  const setPublicImage = async (image: GetPostCardsQuery['getPostCards'][0]) => {
    await imagePublic(image?.id);
    navigate('/', { replace: true });
    showToast({ text: 'Рисунок опубликован', timeout: 1000 });
    return;
  };

  const copyPrompt = (prompt: string) => {
    navigator.clipboard.writeText(prompt);
    showToast({ text: 'Запрос скопирован', timeout: 1000 });
  };

  return (
    <>
      {postCard && (
        <Row>
          <Col style={{ position: 'relative' }} sizeS={4} sizeM={3} sizeL={4} sizeXL={5}>
            <div onClick={handleOpenFullScreen} style={{ marginBottom: '14px' }}>
              <ImageWitchPoetry postCard={postCard} isDelete={true} />
            </div>
          </Col>
          <Col sizeS={4} sizeM={3} sizeL={4} sizeXL={5} offsetXL={1}>
            <TextField
              tabIndex={-1}
              className={'image-info'}
              readOnly
              disabled
              value={postCard.prompt}
              maxLength={140}
              label={'Запрос'}
              contentRight={
                isMobile ? (
                  <div onClick={() => copyPrompt(postCard.prompt)}>
                    <BtnCopyMobile />
                  </div>
                ) : undefined
              }
            ></TextField>
            <TextField
              tabIndex={-1}
              className={'image-info'}
              style={{
                marginTop: '8px'
              }}
              readOnly
              disabled
              value={getStyleName(postCard.image?.style ?? '')}
              maxLength={140}
              label={'Стиль'}
            ></TextField>
            {isDesktop && (
              <>
                <Button
                  view="secondary"
                  scaleOnInteraction={false}
                  text={'Открыть на весь экран'}
                  onClick={handleOpenFullScreen}
                  style={{ width: '100%', opacity: 1, marginTop: '8px' }}
                />
                <BtnShare />
              </>
            )}

            {/*{isDesktop && postCard.user.id !== userId && (*/}
            {/*  <Button*/}
            {/*    scaleOnInteraction={false}*/}
            {/*    text={'Взять за основу'}*/}
            {/*    onClick={() => handleButtonClickGenerate(postCard.prompt)}*/}
            {/*    view={'checked'}*/}
            {/*    style={{ width: '100%', marginTop: '8px', opacity: 1 }}*/}
            {/*  />*/}
            {/*)}*/}
            {isDesktop && postCard.user.id === userId && !postCard.isPublic && (
              <AutoFocusButton
                autoFocus
                scaleOnInteraction={false}
                text={'Опубликовать'}
                onClick={() => setPublicImage(postCard)}
                style={{
                  width: '100%',
                  opacity: 1,
                  marginTop: '8px',
                  background: 'var(--button-primary-default, #FFF)',
                  color: 'black'
                }}
              />
            )}
          </Col>
          {isMobile && (
            <div
              style={{
                display: 'flex',
                gap: '8px',
                marginLeft: '5px',
                width: 'calc(100vw - var(--plasma-grid-margin) * 2)',
                position: 'fixed',
                bottom: '80px'
              }}
            >
              <BtnShare />

              {postCard.user.id === userId && !postCard.isPublic && (
                <AutoFocusButton
                  autoFocus
                  scaleOnInteraction={false}
                  text={'Опубликовать'}
                  onClick={() => setPublicImage(postCard)}
                  style={{
                    minWidth: '50%',
                    opacity: 1,
                    background: '#21A038',
                    color: 'white',
                    marginTop: '8px'
                  }}
                />
              )}

              {postCard.user.id !== userId && (
                <Button
                  text={'Взять за основу'}
                  onClick={() => handleButtonClickGenerate(postCard.prompt)}
                  view={'checked'}
                  style={{
                    width: '100%',
                    opacity: 1,
                    marginTop: '8px'
                  }}
                />
              )}
            </div>
          )}
          {showFullScreen && postCard?.image?.imageUrl && (
            <FullScreenImage src={postCard.image.imageUrl} onClose={handleCloseFullScreen} />
          )}
        </Row>
      )}
    </>
  );
};

export default ImageInfoPage;
