import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

/** Статусы генерации */
export enum GenerationStatus {
  Cancelled = 'CANCELLED',
  CantBeCancelled = 'CANT_BE_CANCELLED',
  Error = 'ERROR',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Ready = 'READY',
  Rejected = 'REJECTED',
  WaitingCaptcha = 'WAITING_CAPTCHA'
}

export type ImageEntity = {
  __typename?: 'ImageEntity';
  colors?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isColors: Scalars['Boolean'];
  prompt: Scalars['String'];
  queryId: Scalars['String'];
  readyEstimationTime?: Maybe<Scalars['DateTime']>;
  status: GenerationStatus;
  style: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createPostcard: PostCardEntity;
  deletePostcard: Scalars['Boolean'];
  likePostcard: Scalars['Boolean'];
  setPublicStatus: Scalars['Boolean'];
};


export type MutationCreatePostcardArgs = {
  input: PostcardCreateInput;
};


export type MutationDeletePostcardArgs = {
  input: PostcardDeleteInput;
};


export type MutationLikePostcardArgs = {
  input: PostcardLikeInput;
};


export type MutationSetPublicStatusArgs = {
  input: PostcardPublicInput;
};

export type PostCardEntity = {
  __typename?: 'PostCardEntity';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  image?: Maybe<ImageEntity>;
  isLikedByMe: Scalars['Boolean'];
  isMy?: Maybe<Scalars['Boolean']>;
  isPublic: Scalars['Boolean'];
  prompt: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: UserEntity;
};

export type PostCardSort = {
  createdAt?: InputMaybe<SortOrder>;
  isPublic?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PostcardCreateInput = {
  prompt: Scalars['String'];
  style?: Scalars['String'];
};

export type PostcardDeleteInput = {
  id: Scalars['String'];
};

export type PostcardFilterInput = {
  id?: InputMaybe<Scalars['String']>;
  isMy?: InputMaybe<Scalars['Boolean']>;
  isMyLike?: InputMaybe<Scalars['Boolean']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  order?: InputMaybe<PostCardSort>;
  skip?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
};

export type PostcardLikeInput = {
  id: Scalars['String'];
  like: Scalars['Boolean'];
};

export type PostcardPublicInput = {
  id: Scalars['String'];
  isPublic: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  getPostCards: Array<PostCardEntity>;
  getPostcardById?: Maybe<PostCardEntity>;
};


export type QueryGetPostCardsArgs = {
  input?: InputMaybe<PostcardFilterInput>;
};


export type QueryGetPostcardByIdArgs = {
  id: Scalars['String'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Subscription = {
  __typename?: 'Subscription';
  postcardStatusChanged: PostCardEntity;
};


export type SubscriptionPostcardStatusChangedArgs = {
  id: Scalars['String'];
};

export type UserEntity = {
  __typename?: 'UserEntity';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  saluteUserId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PostCardFragment = { __typename?: 'PostCardEntity', id: string, prompt: string, isLikedByMe: boolean, isPublic: boolean, isMy?: boolean | null, image?: { __typename?: 'ImageEntity', id: string, status: GenerationStatus, imageUrl?: string | null, prompt: string, colors?: Array<string> | null, style: string, isColors: boolean } | null, user: { __typename?: 'UserEntity', id: string } };

export type CreatePostcardMutationVariables = Exact<{
  input: PostcardCreateInput;
}>;


export type CreatePostcardMutation = { __typename?: 'Mutation', createPostcard: { __typename?: 'PostCardEntity', id: string, prompt: string, isLikedByMe: boolean, isPublic: boolean, isMy?: boolean | null, image?: { __typename?: 'ImageEntity', id: string, status: GenerationStatus, imageUrl?: string | null, prompt: string, colors?: Array<string> | null, style: string, isColors: boolean } | null, user: { __typename?: 'UserEntity', id: string } } };

export type DeletePostcardMutationVariables = Exact<{
  input: PostcardDeleteInput;
}>;


export type DeletePostcardMutation = { __typename?: 'Mutation', deletePostcard: boolean };

export type LikePostcardMutationVariables = Exact<{
  input: PostcardLikeInput;
}>;


export type LikePostcardMutation = { __typename?: 'Mutation', likePostcard: boolean };

export type SetPublicStatusMutationVariables = Exact<{
  input: PostcardPublicInput;
}>;


export type SetPublicStatusMutation = { __typename?: 'Mutation', setPublicStatus: boolean };

export type GetPostcardByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPostcardByIdQuery = { __typename?: 'Query', getPostcardById?: { __typename?: 'PostCardEntity', id: string, prompt: string, isLikedByMe: boolean, isPublic: boolean, isMy?: boolean | null, image?: { __typename?: 'ImageEntity', id: string, status: GenerationStatus, imageUrl?: string | null, prompt: string, colors?: Array<string> | null, style: string, isColors: boolean } | null, user: { __typename?: 'UserEntity', id: string } } | null };

export type GetPostCardsQueryVariables = Exact<{
  input?: InputMaybe<PostcardFilterInput>;
}>;


export type GetPostCardsQuery = { __typename?: 'Query', getPostCards: Array<{ __typename?: 'PostCardEntity', id: string, prompt: string, isLikedByMe: boolean, isPublic: boolean, isMy?: boolean | null, image?: { __typename?: 'ImageEntity', id: string, status: GenerationStatus, imageUrl?: string | null, prompt: string, colors?: Array<string> | null, style: string, isColors: boolean } | null, user: { __typename?: 'UserEntity', id: string } }> };

export type PostcardStatusChangedSubscriptionVariables = Exact<{
  id: Scalars['String'];
}>;


export type PostcardStatusChangedSubscription = { __typename?: 'Subscription', postcardStatusChanged: { __typename?: 'PostCardEntity', id: string, prompt: string, isLikedByMe: boolean, isPublic: boolean, isMy?: boolean | null, image?: { __typename?: 'ImageEntity', id: string, status: GenerationStatus, imageUrl?: string | null, prompt: string, colors?: Array<string> | null, style: string, isColors: boolean } | null, user: { __typename?: 'UserEntity', id: string } } };

export const PostCardFragmentDoc = gql`
    fragment PostCard on PostCardEntity {
  id
  prompt
  isLikedByMe
  isPublic
  isMy
  image {
    id
    status
    imageUrl
    prompt
    colors
    style
    isColors
  }
  user {
    id
  }
}
    `;
export const CreatePostcardDocument = gql`
    mutation createPostcard($input: PostcardCreateInput!) {
  createPostcard(input: $input) {
    ...PostCard
  }
}
    ${PostCardFragmentDoc}`;
export type CreatePostcardMutationFn = Apollo.MutationFunction<CreatePostcardMutation, CreatePostcardMutationVariables>;

/**
 * __useCreatePostcardMutation__
 *
 * To run a mutation, you first call `useCreatePostcardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostcardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostcardMutation, { data, loading, error }] = useCreatePostcardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePostcardMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostcardMutation, CreatePostcardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePostcardMutation, CreatePostcardMutationVariables>(CreatePostcardDocument, options);
      }
export type CreatePostcardMutationHookResult = ReturnType<typeof useCreatePostcardMutation>;
export type CreatePostcardMutationResult = Apollo.MutationResult<CreatePostcardMutation>;
export type CreatePostcardMutationOptions = Apollo.BaseMutationOptions<CreatePostcardMutation, CreatePostcardMutationVariables>;
export const DeletePostcardDocument = gql`
    mutation deletePostcard($input: PostcardDeleteInput!) {
  deletePostcard(input: $input)
}
    `;
export type DeletePostcardMutationFn = Apollo.MutationFunction<DeletePostcardMutation, DeletePostcardMutationVariables>;

/**
 * __useDeletePostcardMutation__
 *
 * To run a mutation, you first call `useDeletePostcardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostcardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostcardMutation, { data, loading, error }] = useDeletePostcardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePostcardMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostcardMutation, DeletePostcardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostcardMutation, DeletePostcardMutationVariables>(DeletePostcardDocument, options);
      }
export type DeletePostcardMutationHookResult = ReturnType<typeof useDeletePostcardMutation>;
export type DeletePostcardMutationResult = Apollo.MutationResult<DeletePostcardMutation>;
export type DeletePostcardMutationOptions = Apollo.BaseMutationOptions<DeletePostcardMutation, DeletePostcardMutationVariables>;
export const LikePostcardDocument = gql`
    mutation likePostcard($input: PostcardLikeInput!) {
  likePostcard(input: $input)
}
    `;
export type LikePostcardMutationFn = Apollo.MutationFunction<LikePostcardMutation, LikePostcardMutationVariables>;

/**
 * __useLikePostcardMutation__
 *
 * To run a mutation, you first call `useLikePostcardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikePostcardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likePostcardMutation, { data, loading, error }] = useLikePostcardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLikePostcardMutation(baseOptions?: Apollo.MutationHookOptions<LikePostcardMutation, LikePostcardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikePostcardMutation, LikePostcardMutationVariables>(LikePostcardDocument, options);
      }
export type LikePostcardMutationHookResult = ReturnType<typeof useLikePostcardMutation>;
export type LikePostcardMutationResult = Apollo.MutationResult<LikePostcardMutation>;
export type LikePostcardMutationOptions = Apollo.BaseMutationOptions<LikePostcardMutation, LikePostcardMutationVariables>;
export const SetPublicStatusDocument = gql`
    mutation setPublicStatus($input: PostcardPublicInput!) {
  setPublicStatus(input: $input)
}
    `;
export type SetPublicStatusMutationFn = Apollo.MutationFunction<SetPublicStatusMutation, SetPublicStatusMutationVariables>;

/**
 * __useSetPublicStatusMutation__
 *
 * To run a mutation, you first call `useSetPublicStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPublicStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPublicStatusMutation, { data, loading, error }] = useSetPublicStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPublicStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetPublicStatusMutation, SetPublicStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPublicStatusMutation, SetPublicStatusMutationVariables>(SetPublicStatusDocument, options);
      }
export type SetPublicStatusMutationHookResult = ReturnType<typeof useSetPublicStatusMutation>;
export type SetPublicStatusMutationResult = Apollo.MutationResult<SetPublicStatusMutation>;
export type SetPublicStatusMutationOptions = Apollo.BaseMutationOptions<SetPublicStatusMutation, SetPublicStatusMutationVariables>;
export const GetPostcardByIdDocument = gql`
    query getPostcardById($id: String!) {
  getPostcardById(id: $id) {
    ...PostCard
  }
}
    ${PostCardFragmentDoc}`;

/**
 * __useGetPostcardByIdQuery__
 *
 * To run a query within a React component, call `useGetPostcardByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostcardByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostcardByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPostcardByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPostcardByIdQuery, GetPostcardByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostcardByIdQuery, GetPostcardByIdQueryVariables>(GetPostcardByIdDocument, options);
      }
export function useGetPostcardByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostcardByIdQuery, GetPostcardByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostcardByIdQuery, GetPostcardByIdQueryVariables>(GetPostcardByIdDocument, options);
        }
export type GetPostcardByIdQueryHookResult = ReturnType<typeof useGetPostcardByIdQuery>;
export type GetPostcardByIdLazyQueryHookResult = ReturnType<typeof useGetPostcardByIdLazyQuery>;
export type GetPostcardByIdQueryResult = Apollo.QueryResult<GetPostcardByIdQuery, GetPostcardByIdQueryVariables>;
export const GetPostCardsDocument = gql`
    query getPostCards($input: PostcardFilterInput) {
  getPostCards(input: $input) {
    ...PostCard
  }
}
    ${PostCardFragmentDoc}`;

/**
 * __useGetPostCardsQuery__
 *
 * To run a query within a React component, call `useGetPostCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostCardsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPostCardsQuery(baseOptions?: Apollo.QueryHookOptions<GetPostCardsQuery, GetPostCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostCardsQuery, GetPostCardsQueryVariables>(GetPostCardsDocument, options);
      }
export function useGetPostCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostCardsQuery, GetPostCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostCardsQuery, GetPostCardsQueryVariables>(GetPostCardsDocument, options);
        }
export type GetPostCardsQueryHookResult = ReturnType<typeof useGetPostCardsQuery>;
export type GetPostCardsLazyQueryHookResult = ReturnType<typeof useGetPostCardsLazyQuery>;
export type GetPostCardsQueryResult = Apollo.QueryResult<GetPostCardsQuery, GetPostCardsQueryVariables>;
export const PostcardStatusChangedDocument = gql`
    subscription postcardStatusChanged($id: String!) {
  postcardStatusChanged(id: $id) {
    ...PostCard
  }
}
    ${PostCardFragmentDoc}`;

/**
 * __usePostcardStatusChangedSubscription__
 *
 * To run a query within a React component, call `usePostcardStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePostcardStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostcardStatusChangedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostcardStatusChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<PostcardStatusChangedSubscription, PostcardStatusChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PostcardStatusChangedSubscription, PostcardStatusChangedSubscriptionVariables>(PostcardStatusChangedDocument, options);
      }
export type PostcardStatusChangedSubscriptionHookResult = ReturnType<typeof usePostcardStatusChangedSubscription>;
export type PostcardStatusChangedSubscriptionResult = Apollo.SubscriptionResult<PostcardStatusChangedSubscription>;