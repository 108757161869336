import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Col, Row } from '@salutejs/plasma-ui';
import { GetPostCardsQuery, useGetPostCardsQuery } from '@/graphql/graphQlApiHooks';
import { useNavigate } from 'react-router-dom';
import ImageWitchPoetry from '@/components/ImageWitchPoetry';
import { AssistantContext } from '@/providers/AssistantProvider';
import { useMain } from '@/store/main';

export type Filter = {
  userId?: string;
  canBePublic?: boolean;
  rating?: boolean;
  skip: number;
  limit: number;
  isMy?: boolean;
  isMyLike?: boolean;
  isPublic?: boolean;
};

interface GalleryProps {
  filter: Filter;
  children?: ReactNode;
}

const Gallery: React.FC<GalleryProps> = ({ filter, children }) => {
  const assistantInstance = useContext(AssistantContext);
  const [filterState, setFilterState] = useState<{ skip: number }>({ skip: 0 });

  const { setGradient } = useMain();

  const navigate = useNavigate();

  const { data, fetchMore } = useGetPostCardsQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      input: { ...filter, order: { updatedAt: 'DESC' } }
    },
    fetchPolicy: 'network-only'
  });

  const lastImageElementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        const newFilter = { ...filter, skip: filterState.skip + filter.limit };
        setFilterState(newFilter);
        fetchMore({
          variables: { input: newFilter }
        });
      }
    });

    if (lastImageElementRef.current) {
      observer.observe(lastImageElementRef.current);
    }

    return () => observer.disconnect();
  }, [data]);

  const handleImageClick = (currentImage: GetPostCardsQuery['getPostCards'][0]) => {
    if (currentImage?.id) navigate(`${currentImage.id}`, { state: { currentImage } });
  };

  useEffect(() => {
    if (!data?.getPostCards) return;
    if (location.pathname.includes('favorites')) {
      assistantInstance.assistantRef?.current?.sendData({
        action: {
          action_id: data?.getPostCards.length > 0 ? 'FAVORITE_WITCH_IMG' : 'FAVORITE_EMPTY'
        }
      });
    } else if (location.pathname.includes('all-generated')) {
      assistantInstance.assistantRef?.current?.sendData({
        action: { action_id: data?.getPostCards.length > 0 ? 'GALLERY_WITCH_IMG' : 'GALLERY_EMPTY' }
      });
    } else if (location.pathname.includes('/')) {
      assistantInstance.assistantRef?.current?.sendData({
        action: { action_id: 'HOME' }
      });
    }
  }, [data]);

  return (
    <>
      <div className={'hidden-desktop'}>{children}</div>
      <Row>
        {data?.getPostCards
          .filter((image) => image.image?.status === 'READY')
          .map((image, index) => (
            <Col
              sizeS={4}
              sizeM={6}
              sizeL={4}
              sizeXL={3}
              key={image.id}
              ref={index === data?.getPostCards.length - 1 ? lastImageElementRef : null}
              style={{
                padding: 'var(--plasma-grid-gutter)'
              }}
            >
              <ImageWitchPoetry
                onClick={handleImageClick}
                postCard={image}
                isHeard={true}
                isGallery={true}
                onFocused={() => {
                  if (
                    image.image?.isColors &&
                    image.image.colors &&
                    image.image.colors?.length >= 2
                  ) {
                    const gradient = `linear-gradient(90deg, ${image.image.colors.join(', ')})`;
                    setGradient(gradient);
                  }
                }}
              />
            </Col>
          ))}
      </Row>
    </>
  );
};

export default Gallery;
