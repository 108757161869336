import { ChangeEvent, FC, RefObject } from 'react';
import { Row, Col, Button } from '@salutejs/plasma-ui';

import ActionButtons from '@/components/ActionButtons';
import BtnPublish from '@/components/BtnPublish';
import BtnShare from '@/components/BtnShare';
import CustomTextArea from '@/components/CustomTextArea';
import ImageWitchPoetry from '@/components/ImageWitchPoetry';
import StyleGenerateSelectorDesktop from '@/components/generate/StyleGenerateSelectorDesktop';
import FullScreenImage from '@/components/FullScreenImage';
import { useGenerateStore } from '@/store/generate';
import { usePostCardStore } from '@/store/postCard';

interface GeneratePageDesktopProps {
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleOpenFullScreen: () => void;
  handleCloseFullScreen: () => void;
  showFullScreen: boolean;
  startGenerate: () => void;
  focusButtonRef: RefObject<HTMLButtonElement>;
}

const GeneratePageDesktop: FC<GeneratePageDesktopProps> = ({
  handleInputChange,
  handleOpenFullScreen,
  handleCloseFullScreen,
  showFullScreen,
  startGenerate,
  focusButtonRef
}) => {
  const { edit, prompt, postCards, currentPostCardIndex, nextCard, previousCard } =
    useGenerateStore((state) => ({
      edit: state.edit,
      currentPostCardIndex: state.currentPostCardIndex,
      prompt: state.prompt,
      postCards: state.postCards,
      nextCard: state.nextCard,
      previousCard: state.previousCard
    }));

  const { postCard } = usePostCardStore((state) => ({
    postCard: state.postCard
  }));

  return (
    <>
      <Row>
        {postCard && (
          <Col sizeS={4} sizeM={6} sizeL={4} sizeXL={5}>
            <div onClick={handleOpenFullScreen} style={{ marginBottom: '14px' }}>
              <ImageWitchPoetry key={postCard.id} postCard={postCard} />
            </div>
          </Col>
        )}

        <Col
          sizeS={postCard ? 4 : 8}
          sizeM={6}
          sizeL={postCard ? 4 : 8}
          sizeXL={postCard ? 5 : 12}
          offsetXL={1}
        >
          <CustomTextArea
            disabled={!edit}
            loading={!postCard?.image?.imageUrl}
            value={prompt}
            onChange={handleInputChange}
            maxLength={140}
          />

          <StyleGenerateSelectorDesktop />

          {currentPostCardIndex >= 0 && (
            <>
              <ActionButtons
                style={{ margin: '0.5rem 0px' }}
                onCancel={previousCard}
                onRetry={startGenerate}
                onRepeat={nextCard}
                disableCancel={currentPostCardIndex === 0}
                disableRetry={!edit && !postCard?.image?.imageUrl}
                disableNext={postCards.length === currentPostCardIndex + 1}
              />
              <div style={{ margin: '14px 0' }}>
                <BtnShare />
              </div>
              <BtnPublish redirectHome />
            </>
          )}

          {currentPostCardIndex === -1 && (
            <Button
              ref={focusButtonRef}
              scaleOnInteraction={false}
              disabled={!prompt.length}
              text="Сгенерировать"
              onTouchStart={prompt.length ? startGenerate : () => ''}
              onClick={prompt.length ? startGenerate : () => ''}
              view="primary"
              style={{ width: '100%' }}
            />
          )}
        </Col>
      </Row>

      {showFullScreen && postCard?.image?.imageUrl && (
        <FullScreenImage src={postCard.image.imageUrl} onClose={handleCloseFullScreen} />
      )}
    </>
  );
};

export default GeneratePageDesktop;
