import { create } from 'zustand';

type Main = {
  title: string;
  setTitle: (id: string) => void;
  icon: string;
  setIcon: (icon: string) => void;
  showHelpSliderComponent: boolean;
  setHideForShowHelpSliderComponent: () => void;
  gradient: string;
  setGradient: (gradient: string) => void;
};

export const useMain = create<Main>((set) => ({
  title: 'Kandinsky',
  icon: '/logo.png',
  setIcon: (value) => set({ icon: value }),
  setTitle: (value) => set({ title: value }),
  showHelpSliderComponent: true,
  setHideForShowHelpSliderComponent: () => set({ showHelpSliderComponent: false }),
  gradient:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.05) 100%), #080808',
  setGradient: (gradient) => set({ gradient })
}));
