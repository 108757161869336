import { useEffect, useState } from 'react';
import { Sheet, Headline3, Button, useToast } from '@salutejs/plasma-ui';
import styled from 'styled-components';
import { TelegramShareButton, WhatsappShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BtnCopy } from '@/components/Icons';
import { usePostCardStore } from '@/store/postCard';
import FullScreenQRCode from '@/components/FullScreenQRCode';
import useDeviceType from '@/hooks/useDeviceType';
import { useLocation, useNavigate } from 'react-router-dom';

const StyledButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  background-color: #333333;
  color: white;
  text-decoration: none;
  border-radius: 16px;
  width: calc(100vw - 48px);
`;

const SharedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  padding-top: 15px;
  padding-bottom: 80px;
`;

const BtnShare = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const { showToast } = useToast();
  const { isMobile } = useDeviceType();
  const navigate = useNavigate();
  const location = useLocation();

  const { postCard } = usePostCardStore((state) => ({
    postCard: state.postCard
  }));

  const shareUrl = postCard?.image?.imageUrl || '';
  const shareText = 'Посмотрите это изображение!';

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('modalqr') === 'open') {
      setShowQRCode(true);
    } else {
      setShowQRCode(false);
    }
  }, [location.search]);

  useEffect(() => {
    const broadcast = new BroadcastChannel('generationActions');

    const handleMessage = (event: MessageEvent) => {
      const { action } = event.data;

      switch (action) {
        case 'share':
          handleOpenQRCode();
          break;
      }
    };

    broadcast.addEventListener('message', handleMessage);

    return () => {
      broadcast.removeEventListener('message', handleMessage);
      broadcast.close();
    };
  }, []);

  const onCopy = () => {
    setIsOpen(false);
    showToast({ text: 'Ссылка на генерацию успешно скопирована', timeout: 1000 });
  };

  const handleOpenQRCode = () => {
    navigate(`${location.pathname}/?modalqr=open`);
    setShowQRCode(true);
  };

  const handleCloseQRCode = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('modalqr');
    navigate(
      `${location.pathname.replace(/\/$/, '')}${
        searchParams.toString() ? `?${searchParams.toString()}` : ''
      }`
    );
    setShowQRCode(false);
  };

  const handleMobileShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Новое изображение',
          text: shareText,
          url: shareUrl
        })
        .then(() => console.log('Успешно поделились'))
        .catch((error) => console.error('Ошибка при операции:', error));
    } else {
      navigator.clipboard.writeText(postCard?.image?.imageUrl || '');
      showToast({ text: 'Ссылка на генерацию успешно скопирована', timeout: 1000 });
    }
  };

  return (
    <>
      {!isMobile && (
        <>
          <Button
            scaleOnInteraction={false}
            text={'Поделиться'}
            onClick={postCard?.image?.imageUrl ? handleOpenQRCode : () => ''}
            disabled={!postCard?.image?.imageUrl}
            style={{ width: '100%', marginTop: '8px' }}
          />
          {showQRCode && postCard?.image?.imageUrl && (
            <FullScreenQRCode link={postCard.image.imageUrl} onClose={handleCloseQRCode} />
          )}
        </>
      )}
      {isMobile && (
        <>
          <Button
            scaleOnInteraction={false}
            disabled={!postCard?.image?.imageUrl}
            text={'Поделиться'}
            onClick={handleMobileShare}
            view={'secondary'}
            style={{ width: '100%', marginTop: '8px', opacity: 1, background: '#272727' }}
          />
          {postCard?.image?.imageUrl && (
            <>
              <Sheet
                isOpen={isOpen}
                withOverlay={true}
                withTransition={true}
                onClose={() => setIsOpen(false)}
                style={{ zIndex: 9999999 }}
              >
                <SharedContainer>
                  <Headline3 style={{ marginBottom: '22px' }}>Поделиться</Headline3>
                  <CopyToClipboard text={shareUrl}>
                    <StyledButton onClick={onCopy}>
                      <div style={{ marginRight: '12px' }}>
                        <BtnCopy />
                      </div>
                      <div> Скопировать ссылку</div>
                    </StyledButton>
                  </CopyToClipboard>
                  <WhatsappShareButton url={shareUrl} separator=" ">
                    <StyledButton>
                      <div style={{ marginRight: '12px' }}>
                        <img src="./images/whats_app.png" alt="" />
                      </div>
                      <div> Отправить в WhatsApp</div>
                    </StyledButton>
                  </WhatsappShareButton>

                  <TelegramShareButton url={shareUrl}>
                    <StyledButton>
                      <div style={{ marginRight: '12px' }}>
                        <img src="./images/telegram.png" alt="" />
                      </div>
                      <div> Отправить в Телеграм</div>
                    </StyledButton>
                  </TelegramShareButton>
                </SharedContainer>
              </Sheet>
            </>
          )}
        </>
      )}
    </>
  );
};

export default BtnShare;
