import { FC, RefObject, useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardMedia, Spinner } from '@salutejs/plasma-ui';
import {
  CreatePostcardMutation,
  usePostcardStatusChangedSubscription,
  useGetPostcardByIdQuery,
  GenerationStatus
} from '@/graphql/graphQlApiHooks';
import styled from 'styled-components';
import SpinnerBlockWrapper from '@/components/wrappers/SpinnerBlockWrapper';
import { useGenerateStore } from '@/store/generate';
import useDeviceType from '@/hooks/useDeviceType';
import BtnHeartMobile from '@/components/favorite/BtnHeartMobile';
import BtnDeleteMobile from '@/components/delete/BtnDeleteMobile';
import { useNavigate } from 'react-router-dom';
import { useMain } from '@/store/main';

type PostcardType = CreatePostcardMutation['createPostcard'];

interface ImageWitchPoetryProps {
  postCard: PostcardType;
  onClick?: (postcard: PostcardType) => void;
  onFocused?: () => void;
  isDelete?: boolean;
  isHeard?: boolean;
  isGallery?: boolean;
  onClickDelete?: (postcard: PostcardType) => void;
}

const WrapperCard = styled(Card)`
  cursor: pointer;
  aspect-ratio: 1 / 1;
  z-index: 6;
`;

const ImageWitchPoetry: FC<ImageWitchPoetryProps> = ({
  postCard,
  onClick,
  onFocused,
  isHeard = true,
  isDelete = false,
  isGallery = false
}) => {
  const [postCardCurrent, setPostCard] = useState<PostcardType>(postCard);
  const [imgUrl, setImgUrl] = useState<string>('');
  const focusImageRef = useRef<HTMLElement>(null);

  const { isMobile } = useDeviceType();

  const { updateCardInPostCards, setLoadingImage } = useGenerateStore((state) => ({
    updateCardInPostCards: state.updateCardInPostCards,
    setLoadingImage: state.setLoadingImage
  }));

  const { setGradient } = useMain();
  const { data, error } = usePostcardStatusChangedSubscription({
    variables: { id: postCardCurrent.id }
  });
  const { data: queryData, refetch } = useGetPostcardByIdQuery({
    variables: { id: postCardCurrent.id },
    skip: true
  });

  useEffect(() => {
    if (isGallery) return;
    if (postCard.image?.isColors && postCard.image.colors && postCard.image.colors?.length >= 2) {
      const gradient = `linear-gradient(90deg, ${postCard.image.colors.join(', ')})`;
      console.log('gradient', gradient);
      setGradient(gradient);
    }
  }, [postCard.image?.isColors, postCard.image?.colors, setGradient]);

  useEffect(() => {
    if (data?.postcardStatusChanged) {
      const updatedPostCard = data.postcardStatusChanged;
      setPostCard((prevPostCard) => ({
        ...prevPostCard,
        ...updatedPostCard
      }));
      updateCardInPostCards(updatedPostCard);
    }
  }, [data, error, updateCardInPostCards]);

  useEffect(() => {
    const checkAndSubscribe = async () => {
      if (
        !postCardCurrent.image?.imageUrl ||
        postCardCurrent.image.status !== GenerationStatus.Ready
      ) {
        const result = await refetch();
        if (result.data?.getPostcardById) {
          const updatedPostCard = result.data.getPostcardById;
          setPostCard(updatedPostCard);
          updateCardInPostCards(updatedPostCard);
        }
      }
    };

    checkAndSubscribe();
  }, [postCardCurrent.id, refetch, updateCardInPostCards]);

  const handleFocus = () => {
    if (onFocused) {
      onFocused();
    }

    document.activeElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  };

  const handleClick = (postcard: PostcardType) => {
    if (onClick) {
      onClick(postcard);
    }
  };

  const isLoading = () => {
    if (postCardCurrent.image) {
      return postCardCurrent.image.status === GenerationStatus.Pending;
    }
  };

  useEffect(() => {
    const isNotPages = !['/favorites', '/all-generated', '/'].includes(location.pathname);

    if (isNotPages && !isLoading()) {
      focusImageRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    setLoadingStore(postCardCurrent.image?.status === GenerationStatus.Pending);
    if (postCardCurrent.image?.imageUrl) return setImgUrl(postCardCurrent.image.imageUrl);
  }, [postCardCurrent]);

  const setLoadingStore = (status: boolean) => {
    setLoadingImage(status);
  };

  const navigate = useNavigate();
  const handleHomeClick = () => navigate('/');

  return (
    <>
      {postCardCurrent && (
        <>
          <WrapperCard
            ref={focusImageRef as RefObject<HTMLDivElement>}
            style={{ borderRadius: '1rem' }}
            outlined
            tabIndex={isLoading() ? -1 : 0}
            onFocus={handleFocus}
            onClick={() => handleClick(postCardCurrent)}
          >
            <CardBody>
              {isLoading() ? (
                <SpinnerBlockWrapper>
                  <Spinner color="#fff" size={40} />
                </SpinnerBlockWrapper>
              ) : (
                <CardMedia loading={'lazy'} src={imgUrl} placeholder={imgUrl} ratio="1/1" />
              )}
            </CardBody>
            {isMobile && isHeard && <BtnHeartMobile postCard={postCard} />}
            {isMobile && isDelete && (
              <div
                onClick={(e) => e.stopPropagation()}
                style={{
                  position: 'absolute',
                  right: '8px',
                  bottom: '8px',
                  zIndex: 999
                }}
              >
                <BtnDeleteMobile postCard={postCard} onClick={() => handleHomeClick()} />
              </div>
            )}
          </WrapperCard>
        </>
      )}
    </>
  );
};

export default ImageWitchPoetry;
