import { MouseEvent, useEffect, useState } from 'react';
import { Button, useToast } from '@salutejs/plasma-ui';
import { useLikePostcardMutation } from '@/graphql/graphQlApiHooks';
import { BtnNoHeardAction, BtnNoHeardMobile } from '@/components/Icons';
import { usePostCardStore } from '@/store/postCard';
import { useCheckWitch } from '@/hooks/useCheckWitch';
import { useGenerateStore } from '@/store/generate';

const BtnHeartDesktop = () => {
  const { showToast } = useToast();
  const [isLiked, setIsLiked] = useState(false);
  const [likePostcard] = useLikePostcardMutation();
  const { onMoreWidth } = useCheckWitch();

  const { postCard } = usePostCardStore((state) => ({
    postCard: state.postCard
  }));

  const { updateCardInPostCards } = useGenerateStore((state) => ({
    updateCardInPostCards: state.updateCardInPostCards
  }));

  useEffect(() => {
    if (postCard) {
      setIsLiked(postCard.isLikedByMe);
    }
  }, [postCard]);

  const setFavorite = async (id: string, isLike: boolean) => {
    const previousIsLiked = isLiked;
    if (postCard) updateCardInPostCards({ ...postCard, isLikedByMe: isLike });
    setIsLiked(isLike);
    try {
      await likePostcard({ variables: { input: { id, like: isLike } } });
    } catch (error) {
      setIsLiked(previousIsLiked);
      showToast({ text: 'Ошибка при изменении состояния избранного', timeout: 1000 });
      console.error('Ошибка при обновлении избранного:', error);
    }
  };

  const handleHeartClick = (event: MouseEvent) => {
    if (!postCard) return;

    event.stopPropagation();
    setFavorite(postCard?.id, !isLiked);

    showToast({
      text: isLiked ? 'Рисунок удален из избранного' : 'Рисунок добавлен в избранное',
      timeout: 1000
    });
  };

  if (!postCard?.image?.imageUrl) return null;

  const getTextBtn = () => {
    return isLiked ? 'Убрать из избранного' : 'Добавить в избранное';
  };

  return (
    <>
      <div className={'btn-delete'} style={{ right: '13rem' }}>
        <Button
          scaleOnInteraction={false}
          size="s"
          onClick={handleHeartClick}
          text={onMoreWidth(560) ? getTextBtn() : ''}
          style={{ marginRight: '12px' }}
          contentLeft={!isLiked ? <BtnNoHeardAction /> : <BtnNoHeardMobile />}
        />
      </div>
    </>
  );
};

export default BtnHeartDesktop;
