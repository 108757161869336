import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGenerateStore } from '@/store/generate';
import { usePostCardStore } from '@/store/postCard';
import { useMain } from '@/store/main';
import { AssistantContext } from '@/providers/AssistantProvider';
import useImageManager from '@/hooks/useImageManager';
import useDeviceType from '@/hooks/useDeviceType';

import GenerateScreenDesktop from '@/components/generateDesktop/GenerateScreenDesktop';

import GeneratePageDesktop from './GeneratePageDesktop';
import GeneratePageMobile from './GeneratePageMobile';

const GeneratePage = () => {
  const [isGenerateCommand, setIsGenerateCommand] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const focusButtonRef = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();
  const { isMobile, isDesktop } = useDeviceType();

  const { setTitle, setIcon } = useMain((state) => ({
    setTitle: state.setTitle,
    setIcon: state.setIcon
  }));

  const {
    setEdit,
    prompt,
    setPrompt,
    postCards,
    currentPostCardIndex,
    setCurrentCardIndex,
    clearAll
  } = useGenerateStore((state) => ({
    setEdit: state.setEdit,
    currentPostCardIndex: state.currentPostCardIndex,
    setCurrentCardIndex: state.setCurrentCardIndex,
    prompt: state.prompt,
    postCards: state.postCards,
    setPrompt: state.setPrompt,
    clearAll: state.clearAll
  }));

  const { postCard, setPostCard, clearPostCard } = usePostCardStore((state) => ({
    setPostCard: state.setPostCard,
    postCard: state.postCard,
    clearPostCard: state.clearPostCard
  }));

  const { generatePhrase } = useContext(AssistantContext);
  const { generate } = useImageManager();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPrompt(event.target.value);
  };

  const startGenerate = async () => {
    setEdit(false);
    if (!prompt && postCard) {
      setPrompt(postCard.prompt);
    }
    if (prompt) {
      await generate();
    }
  };

  useEffect(() => {
    return () => {
      clearAll();
      clearPostCard();
    };
  }, []);

  useEffect(() => {
    focusButtonRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (generatePhrase) {
      if (!prompt) {
        setPrompt(generatePhrase.image);
      }
      setIsGenerateCommand(true);
    }
  }, [generatePhrase]);

  useEffect(() => {
    if (isGenerateCommand) {
      startGenerate();
      setIsGenerateCommand(false);
    }
  }, [isGenerateCommand]);

  useEffect(() => {
    setIcon('');
    setTitle('');

    if (isMobile && !postCard?.image?.imageUrl && !prompt) {
      setTitle('Создание рисунка');
    }

    if (isDesktop && !postCard?.image?.imageUrl && !prompt) {
      setTitle('Создание запроса');
    }

    return () => {
      setTitle('Kandinsky');
      setIcon('/logo.png');
    };
  }, [postCards, prompt, isDesktop, isMobile]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('modal') === 'open') {
      setShowFullScreen(true);
    } else {
      setShowFullScreen(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (postCards[currentPostCardIndex]) {
      setPostCard(postCards[currentPostCardIndex]);
      return;
    }
    setPostCard(postCards[postCards.length - 1]);
    setCurrentCardIndex(postCards.length - 1);
  }, [currentPostCardIndex, postCards]);

  console.log(
    'isDesktop && postCards.length === 0 && !prompt',
    isDesktop,
    postCards.length === 0,
    !prompt,
    isDesktop && postCards.length === 0 && !prompt
  );
  if (isDesktop && postCards.length === 0 && !prompt) {
    return <GenerateScreenDesktop />;
  }

  if (isDesktop && !postCard) return null;

  const handleCloseFullScreen = () => {
    setShowFullScreen(false);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('modal');
    navigate(
      `${location.pathname.replace(/\/$/, '')}${
        searchParams.toString() ? `?${searchParams.toString()}` : ''
      }`
    );
  };

  const handleOpenFullScreen = () => {
    setShowFullScreen(true);
    navigate(`${location.pathname}/?modal=open`);
  };

  return (
    <>
      {isDesktop ? (
        <GeneratePageDesktop
          handleInputChange={handleInputChange}
          handleOpenFullScreen={handleOpenFullScreen}
          handleCloseFullScreen={handleCloseFullScreen}
          showFullScreen={showFullScreen}
          startGenerate={startGenerate}
          focusButtonRef={focusButtonRef}
        />
      ) : (
        <GeneratePageMobile
          handleInputChange={handleInputChange}
          handleOpenFullScreen={handleOpenFullScreen}
          handleCloseFullScreen={handleCloseFullScreen}
          showFullScreen={showFullScreen}
          startGenerate={startGenerate}
          focusButtonRef={focusButtonRef}
        />
      )}
    </>
  );
};

export default GeneratePage;
