import React, { useEffect, useRef, useState, createContext } from 'react';
import { AssistantSmartAppCommand } from '@salutejs/client/dist/typings';
import {
  AssistantAppState,
  AssistantClientCommand,
  createAssistant,
  createSmartappDebugger
} from '@salutejs/client';

interface RunAppCommand extends AssistantSmartAppCommand {
  type: 'smart_app_data';
  smart_app_data: {
    type: 'run_app';
    payload: { accountId: string };
  };
}

type CustomAssistantCommand = AssistantClientCommand | RunAppCommand;

interface GeneratePhrase {
  image: string;
}

export interface AssistantContextValue {
  assistantRef: React.RefObject<ReturnType<typeof createAssistant>> | null;
  userId: string;
  accessToken: string;
  generatePhrase: null | GeneratePhrase;
}

export const AssistantContext = createContext<AssistantContextValue>({
  assistantRef: null,
  userId: '',
  accessToken: '',
  generatePhrase: null
});

const IS_DEVELOPMENT = import.meta.env.VITE_ENV === 'development';
const DEV_TOKEN = import.meta.env.VITE_DEV_TOKEN;
const DEV_PHRASE = import.meta.env.VITE_DEV_PHRASE;

const useAssistant = () => {
  const assistantRef = useRef<ReturnType<typeof createAssistant> | null>(null);
  const [userId, setUserId] = useState<string>('');
  const [accessToken, setAccessToken] = useState<string>('');
  const [generatePhrase, setGeneratePhrase] = useState<GeneratePhrase | null>(null);

  const getState = () => assistantState.current;

  const handleErrors = () => {
    throw new Error('Ошибка при выполнении запроса на получение токена');
  };

  const fetchToken = async (accountId: string) => {
    if (IS_DEVELOPMENT) {
      accountId = 'webdbg_userid_671hurtbn0tu177wpp8el9';
    }

    try {
      const response = await fetch(`${import.meta.env.VITE_SERVER_URL}/user/auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          saluteUserId: `${accountId}`
        })
      });

      const { jwt, userId } = await response.json();

      if (userId && jwt) {
        setUserId(userId);
        setAccessToken(jwt);
      } else {
        handleErrors();
      }
    } catch (error) {
      handleErrors();
    }
  };

  const assistantState: { current: AssistantAppState } = {
    current: {}
  };

  const processCommand = async (command: CustomAssistantCommand) => {
    const broadcast = new BroadcastChannel('generationActions');

    console.log('command1', command);
    switch (command.type) {
      case 'smart_app_data':
        switch (command.smart_app_data.type) {
          case 'run_app':
            console.log(command.smart_app_data.payload);
            console.log('run');
            await fetchToken(command.smart_app_data.payload.accountId);
            break;
          case 'close_app':
            assistantRef.current?.close();
            break;
          case 'start_generate':
            onStartGenerate(command.smart_app_data.payload);
            break;
          case 'next_generate':
          case 'RIGHT':
            broadcast.postMessage({ action: 'next' });
            break;
          case 'previous_generate':
          case 'LEFT':
            broadcast.postMessage({ action: 'previous' });
            break;
          case 're_generate':
            broadcast.postMessage({ action: 'retry' });
            break;
          case 'publish':
            broadcast.postMessage({ action: 'publish' });
            break;
          case 'share':
            broadcast.postMessage({ action: 'share' });
            break;
          case 'openFavorites':
            broadcast.postMessage({ action: 'openFavorites' });
            break;
        }
    }
  };

  const assistantInit = () => {
    let assistant = undefined;
    if (IS_DEVELOPMENT) {
      console.log('IS DEVELOPMENT');
      if (!DEV_TOKEN || !DEV_PHRASE) {
        throw new Error('Invalid token or phrase');
      }

      assistant = createSmartappDebugger({
        token: DEV_TOKEN,
        initPhrase: DEV_PHRASE,
        getState
      });
    }

    assistant = createAssistant({ getState });

    assistant.on('data', processCommand);

    return assistant;
  };

  const onStartGenerate = (command: GeneratePhrase) => {
    if (command) setGeneratePhrase(command);
    setTimeout(() => {
      setGeneratePhrase(null);
    }, 1000);
  };

  useEffect(() => {
    assistantRef.current = assistantInit();
  }, []);

  return {
    assistantRef,
    userId,
    accessToken,
    generatePhrase
  };
};

interface AssistantProviderProps {
  children: React.ReactNode;
}
const AssistantProvider: React.FC<AssistantProviderProps> = ({ children }) => {
  const assistantInstance = useAssistant();

  return (
    <AssistantContext.Provider value={assistantInstance}>{children}</AssistantContext.Provider>
  );
};

export { AssistantProvider };
