import { FC, CSSProperties, useEffect } from 'react';
import { Button, useToast } from '@salutejs/plasma-ui';
import { useNavigate } from 'react-router-dom';
import { GetPostCardsQuery, useSetPublicStatusMutation } from '@/graphql/graphQlApiHooks';
import { usePostCardStore } from '@/store/postCard';
import { useGenerateStore } from '@/store/generate';

interface PublishBtnProps {
  redirectHome?: boolean;
  text?: string;
  style?: CSSProperties;
}

const BtnPublish: FC<PublishBtnProps> = ({
  redirectHome,
  text = 'Опубликовать для всех',
  style
}) => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const { postCard } = usePostCardStore((state) => ({
    postCard: state.postCard
  }));

  const { updateCardInPostCards, setEdit } = useGenerateStore((state) => ({
    setEdit: state.setEdit,
    updateCardInPostCards: state.updateCardInPostCards
  }));

  useEffect(() => {
    const broadcast = new BroadcastChannel('generationActions');

    const handleMessage = (event: MessageEvent) => {
      const { action } = event.data;

      switch (action) {
        case 'publish':
          if (postCard) setPublicImage(postCard);
          break;
      }
    };

    broadcast.addEventListener('message', handleMessage);

    return () => {
      broadcast.removeEventListener('message', handleMessage);
      broadcast.close();
    };
  }, [postCard]);

  const [postcard_MakePublic] = useSetPublicStatusMutation();
  const imagePublic = async (id: string) => {
    await postcard_MakePublic({
      variables: {
        input: { id, isPublic: true }
      }
    });
  };

  const setPublicImage = async (image: GetPostCardsQuery['getPostCards'][0]) => {
    await imagePublic(image?.id);
    if (postCard) updateCardInPostCards({ ...postCard, isPublic: true });
    setEdit(false);
    if (redirectHome) {
      navigate('/');
      showToast({ text: 'Рисунок опубликован', timeout: 1000 });
      return;
    }
    navigate(`/${image.id}`, { replace: true });
  };

  if (!postCard || !postCard.isMy || postCard.isPublic) return null;

  return (
    <Button
      scaleOnInteraction={false}
      disabled={!postCard.image?.imageUrl}
      text={text}
      onClick={() => setPublicImage(postCard)}
      view={'checked'}
      style={{ width: '100%', zIndex: 2, ...style }}
    />
  );
};

export default BtnPublish;
