import { IconDisclosureRight, IconName } from '@salutejs/plasma-icons';
import { StylesImg } from '@/hooks/useImageManager';
import './history-sheet.css';
import { useGenerateStore } from '@/store/generate';
import { Button, CellIcon, TextField } from '@salutejs/plasma-ui';
import SelectStyle from '@/pages/SelectStyle';
import { useEffect } from 'react';
import useDeviceType from '@/hooks/useDeviceType';
import { useNavigate } from 'react-router-dom';

export interface Config {
  title: string;
  icon?: IconName;
  image: string;
  styleName: StylesImg;
}

const StyleGenerateSelectorDesktop = () => {
  const { isMobile } = useDeviceType();
  const navigate = useNavigate();
  const { imgStyle, showStyleSelect, setShowStyleSelect, loadingImage } = useGenerateStore(
    (state) => ({
      imgStyle: state.imgStyle,
      showStyleSelect: state.showStyleSelect,
      loadingImage: state.loadingImage,
      setShowStyleSelect: state.setShowStyleSelect
    })
  );

  useEffect(() => {
    return handleCloseFullScreen();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('style') === 'open') {
      setShowStyleSelect(true);
    } else {
      setShowStyleSelect(false);
    }
  }, [location.search]);

  const handleCloseFullScreen = () => {
    setShowStyleSelect(false);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('style');
    navigate(
      `${location.pathname.replace(/\/$/, '')}${
        searchParams.toString() ? `?${searchParams.toString()}` : ''
      }`
    );
  };

  const handleOpenStyle = () => {
    setShowStyleSelect(true);
    navigate(`${location.pathname}/?style=open`);
  };

  return (
    <>
      <TextField
        disabled
        style={{ marginBottom: '12px' }}
        label="Стиль"
        value={imgStyle.title}
        contentRight={
          !loadingImage ? (
            <Button
              scaleOnInteraction={false}
              contentLeft={<IconDisclosureRight />}
              pin="circle-circle"
              view={'clear'}
              size={'s'}
              onClick={handleOpenStyle}
            />
          ) : undefined
        }
        contentLeft={
          imgStyle.image && imgStyle.image !== '/images/style/none.jpg' ? (
            <CellIcon
              as="img"
              src={imgStyle.image}
              alt={imgStyle.title}
              style={{ margin: '-15px' }}
            />
          ) : undefined
        }
      />
      {showStyleSelect && (
        <div className={`style-modal ${!isMobile ? 'non-mobile' : ''}`}>
          <SelectStyle handleCloseStyle={handleCloseFullScreen} />
        </div>
      )}
    </>
  );
};

export default StyleGenerateSelectorDesktop;
