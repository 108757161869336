import { DeviceThemeProvider, ToastProvider } from '@salutejs/plasma-ui';
import Routes from '@/routes';
import { GlobalStyle } from '@/layouts/GlobalStyle';
import styled from 'styled-components';
import { useContext, useEffect } from 'react';
import { AssistantContext } from '@/providers/AssistantProvider';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { GradientBackground } from '@/components/GradientBackground';
import { useMain } from '@/store/main';
import { useGenerateStore } from '@/store/generate';

const detectDeviceCallback = () => {
  return window.navigator.userAgent.toLowerCase().includes('sberbox') ? 'sberBox' : 'mobile';
};

const AppContainer = styled.div`
  padding: 0 0 20vh;

  @media (min-width: 1024px) {
    padding: 0 0 10vh;
  }
`;

function App() {
  const navigate = useNavigate();

  const isGenerate = location.pathname === '/generate';
  const { generatePhrase } = useContext(AssistantContext);

  const { gradient } = useMain();

  const { setPrompt } = useGenerateStore((state) => ({
    setPrompt: state.setPrompt
  }));

  useEffect(() => {
    if (generatePhrase && !isGenerate) {
      setPrompt(generatePhrase.image);
      navigate('/generate');
    }
  }, [generatePhrase]);

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      navigate('/');
      event.preventDefault();
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate]);

  useEffect(() => {
    const deviceInfo = {
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      language: navigator.language
    };

    Sentry.captureMessage('userAgent', {
      level: 'info',
      extra: deviceInfo
    });
  }, []);

  return (
    <DeviceThemeProvider detectDeviceCallback={detectDeviceCallback}>
      <ToastProvider>
        <GradientBackground gradient={gradient} />
        <AppContainer>
          <GlobalStyle />
          <Routes />
        </AppContainer>
      </ToastProvider>
    </DeviceThemeProvider>
  );
}

export default App;
