import React, { useEffect } from 'react';
import QRCode from 'react-qr-code';

interface FullScreenQRCodeProps {
  link: string;
  onClose: () => void;
}

const FullScreenQRCode: React.FC<FullScreenQRCodeProps> = ({ link, onClose }) => {
  useEffect(() => {
    const handleKeyDown = () => {
      onClose();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#000'
      }}
    >
      <p
        style={{
          color: 'white',
          marginBottom: '1rem',
          fontSize: '24px',
          fontWeight: 600,
          lineHeight: '30px',
          width: '400px'
        }}
      >
        Отсканируйте QR-код камерой телефона, чтобы открыть ссылку на рисунок
      </p>
      <div className={'qr-wrapper'}>
        <QRCode value={link} />
      </div>
    </div>
  );
};

export default FullScreenQRCode;
